import { api } from ":mods";
import { CONST, model } from "..";

export async function detailsGet(showcase: number | string): Promise<model.ShowcaseDetails> {
  return api.getAuth(CONST.ENDPOINTS.admin.detail_showcase(showcase)).then((res) => {
    const p = res.data;
    p.data = p.data.show_cases;
    console.log("res.data is :: ", p);
    return p;
  });
}
